import React from "react";
import styled from "styled-components";

const Content = styled.div`
  width: 100%;
  max-width: 48rem;
  margin: 0 auto 5rem;
  padding: 3rem;
  background: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);

  p {
    margin: 0 0 1rem;
    line-height: 1.6;
  }
`;

export default ({ children, ...rest }) => {
  return <Content {...rest}>{children}</Content>;
};
