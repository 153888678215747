import React from "react";

// Layout
import Layout from "../components/layout";
import Contain from "../components/contain";
import Content from "../components/content";
import HeroPage from "../components/hero-page";
import Heading from "../components/heading";
import Cta from "../components/cta";
import Seo from "../components/seo";

export default ({ location }) => {
  return (
    <Layout location={location}>
      <Seo
        title="Terms | Quickpage"
        description="Follow up better. Close deals faster. With video."
        keywords="video, email, video email"
        url="https://sutton.quickpage.io/terms"
      />

      <HeroPage>
        <Contain>
          <Heading textCenter>Terms of Service</Heading>
        </Contain>
      </HeroPage>
      <Contain>
        <Content>
          <p>
            PLEASE READ THESE TERMS OF SERVICE (“TERMS”) CAREFULLY, AS THEY
            DESCRIBE YOUR RIGHTS AND RESPONSIBILITIES AND FORM A LEGALLY BINDING
            AGREEMENT BETWEEN YOU (THE “USER” OR “YOU”) AND QUICKPAGE INC. (“WE”
            or “QUICKPAGE”) REGARDING YOUR ACCESS TO AND USE OF OUR WEBSITE,
            MOBILE APPLICATIONS, SOFTWARE, AND CONTENT AVAILABLE IN AND THROUGH
            THEM (“APP SERVICES”). IF, FOR ANY REASON, YOU ARE UNABLE OR
            UNWILLING TO AGREE TO ALL TERMS, YOU MAY NOT USE OUR APP SERVICES.
            BY DOWNLOADING OR USING ANY OF THE APP SERVICES, YOU SIGNIFY YOUR
            AFFIRMATIVE ASSENT TO BOTH THESE TERMS AND QUICKPAGE’S PRIVACY
            POLICY, WHICH IS INCORPORATED INTO THESE TERMS BY REFERENCE.
          </p>
          <p>
            <strong>1. Use of the App Services</strong>
          </p>
          <p>
            These Terms of Service apply to all users of the App Services.
            Information provided by our users through the App Services may
            contain links to third party websites that are not owned or
            controlled by Quickpage. We have no control over, and assume no
            responsibility for, the content, privacy policies, or practices of
            any third party websites. In addition, Quickpage will not and cannot
            censor or edit the content of any third party site. By using the App
            Services, you expressly acknowledge and agree that Quickpage shall
            not be responsible for any damages, claims, or other liability
            arising from or related to your use of any third party website.
          </p>
          <p>
            By signing up I agree to receive coupons, promotions, surveys, and
            updates via email and phone about Quickpage it's partners.
          </p>

          <p>
            <strong>Payment, Refunds, Upgrading and Downgrading Terms</strong>
          </p>
          <p>
            The Service is offered with a free trial for 7 days. If you need
            more than 7 days, you will only be able to continue using the
            Service by paying in advance for additional usage. If you fail to
            pay for additional usage, your account will be frozen and
            inaccessible until payment is made.
          </p>
          <p>
            For any upgrade or downgrade in plan level, will result in the new
            rate being charged at the next billing cycle. There will be no
            prorating for downgrades in between billing cycles.
          </p>
          <p>
            Downgrading your Service may cause the loss of features or capacity
            of your account. The Company does not accept any liability for such
            loss.
          </p>
          <p>
            All fees are exclusive of all taxes, levies, or duties imposed by
            taxing authorities, and you shall be responsible for payment of all
            such taxes, levies, or duties, excluding only United States (federal
            or state) taxes. Where required, The Company will collect those
            taxes on behalf of taxing authority and remit those taxes to taxing
            authorities.
          </p>

          <p>
            <strong>Cancellation and Termination</strong>
          </p>
          <p>
            You are solely responsible for properly canceling your account. An
            email, phone or live chat request to cancel your account is not
            considered cancellation. You can cancel your account at any time by
            clicking on the Billing link in top navigation. The Billing screen
            provides a simple no-questions-asked cancellation link.
          </p>
          <p>
            All of your content will be inaccessible from the Service
            immediately upon cancellation. Within 30 days, all content will be
            permanently deleted from backups and logs. This information can not
            be recovered once it has been permanently deleted.
          </p>
          <p>
            If you cancel the Service before the end of your current paid up
            month, your cancellation will take effect immediately, and you will
            not be charged again. But there will not be any prorating of unused
            time in the last billing cycle.
          </p>
          <p>
            The Company, in its sole discretion, has the right to suspend or
            terminate your account and refuse any and all current or future use
            of the Service for any reason at any time. Such termination of the
            Service will result in the deactivation or deletion of your Account
            or your access to your Account, and the forfeiture and
            relinquishment of all content in your account. The Company reserves
            the right to refuse service to anyone for any reason at any time.
          </p>

          <p>
            <strong>Modifications to the Service and Prices</strong>
          </p>
          <p>
            The Company reserves the right at any time and from time to time to
            modify or discontinue, temporarily or permanently, any part of the
            Service with or without notice.
          </p>
          <p>
            Prices of all Services are subject to change upon 30 days notice
            from us. Such notice may be provided at any time by posting the
            changes to the Quickpage site or the Service itself.
          </p>
          <p>
            The Company shall not be liable to you or to any third party for any
            modification, price change, suspension or discontinuance of the
            Service.
          </p>

          <p>
            <strong>2. Access</strong>
          </p>
          <p>
            Subject to your compliance with these Terms, Quickpage hereby grants
            you permission to use the App Services, provided that (a) your use
            of the App Services as permitted is solely for your personal use,
            (b) you do not resell or charge others for use of or access to the
            App Services, (c) you will not duplicate, transfer, give access to,
            copy, or distribute any part of the App Services in any medium
            without Quickpage’s prior written authorization, (d) you will not
            attempt to reverse engineer, alter, or modify any part of the App
            Services, and (e) you otherwise comply with the terms and conditions
            of these Terms and the Privacy Policy.
          </p>
          <p>
            In order to access and use the features of the App Services, you
            acknowledge and agree that you will have to provide Quickpage with
            your name and email address, all of which will be subject to
            Quickpage’s Privacy Policy. Please keep in mind that your name will
            be viewable by other users of the App Services.
          </p>
          <p>
            <strong>3. Responsible Use</strong>
          </p>
          <p>
            You may use the App Services for lawful purposes and in accordance
            with these Terms. You may not use the App Services (a) for any
            purpose that is unlawful or prohibited by these Terms, (b) to cause
            harm or damage to any person or entity, (c) to interfere with the
            proper operation of the App Services, or impair, overburden, or
            disable the same, (d) to copy or harvest any personally identifiable
            information from the App Services or its users, including, without
            limitation, names and email addresses, (e) to engage in any conduct
            that restricts or inhibits any other user from using or enjoying the
            App Services, or (f) to upload, post, or transmit any material that
            violates any law, infringes on the rights of any third party, or
            contains defamatory, libelous, abusive, obscene, or otherwise
            objectionable material, as determined by Quickpage in its sole
            discretion. Harassment in any manner or form in connection with use
            of the App Services, including via e-mail or chat, is strictly
            forbidden. You may not upload commercial content onto the App
            Services.
          </p>
          <p>
            The App Services are not intended for use by anyone under the age of
            18. By accessing, using, or submitting information to or through the
            App Services, you affirm that you are (a) 18 years of age or older,
            (b) an emancipated minor, or (c) over the age of 13 and possessing
            legal parental or guardian consent to enter into these Terms. If you
            are a parent or legal guardian and you provide consent to your
            dependent’s access to and use of the App Services, you agree to be
            bound by these terms.
          </p>
          <p>
            <strong>4. User Submissions</strong>
          </p>
          <p>
            The App Services allow users to submit text, photos, videos, and
            other communications (“User Submissions”). These User Submissions
            may be hosted, shared, and/or published as part of the App Services,
            and may be visible, along with your name and/or email address, to
            other users of the App Services who are communicating or interacting
            with you in several possible ways through the App Services. Given
            the fact that what you submit may be viewable to others, do not
            submit information or content that you do not want seen by others.
          </p>
          <p>
            You shall be solely responsible for your own User Submissions and
            the consequences of posting or publishing them. Because Quickpage is
            only acting as a repository of data, information provided by users,
            including, but not limited to, content, statuses, information,
            opinions, and links to third party sites, do not necessarily
            represent the views or opinions of Quickpage and Quickpage makes no
            guarantees as to the validity, accuracy, or legal status of any such
            information.
          </p>
          <p>
            In connection with your User Submissions, you affirm, represent, and
            warrant that (a) you own or have the necessary license, rights,
            consents, and permissions to use, and also authorize Quickpage to
            use, all patent, trademark, trade secret, copyright, or other
            proprietary rights in and to any and all User Submissions to enable
            inclusion and use of the User Submissions in the manner contemplated
            by the App Services and these Terms, and (b) you have the written
            consent, release, and/or permission, as needed, to submit the
            information in your User Submission. To be clear, you retain all of
            your ownership rights in your User Submissions, if any, but you must
            have the ownership rights in the first place.
          </p>
          <p>
            By submitting the User Submissions to Quickpage through the App
            Services, you hereby grant to Quickpage a worldwide, non-exclusive,
            royalty-free, and transferable license to use, reproduce,
            distribute, prepare derivative works of, display, and perform the
            User Submissions in connection with the App Services and Quickpage’s
            (and it’s successor’s) business, including, without limitation, for
            promoting and redistributing part or all of the App Services in any
            media formats and through any media channels. You also hereby grant
            each subscriber to your User Submissions on the App Services a
            non-exclusive license to access your User Submissions through the
            App Services. The foregoing license by you terminates once you
            remove or delete a User Submission from the App Services.
          </p>
          <p>
            In connection with User Submissions, you further agree that you will
            not (a) submit material that is copyrighted, protected by trade
            secret, or otherwise subject to third party proprietary rights,
            including privacy and publicity rights, unless you are the owner of
            such rights or have permission from their rightful owner to post the
            material and to grant Quickpage all of the license rights granted
            herein, (b) publish falsehoods or misrepresentations that could
            damage other users, third parties, or Quickpage, (c) impersonate
            another person, (d) submit content that is unlawful, threatening,
            abuse, libelous, defamatory, invasive of privacy, vulgar, obscene,
            profane, pornographic, filthy, excessively violent, harassing, or
            otherwise objectionable, (e) send or store material containing
            software viruses, worms, Trojan horses, or other harmful computer
            code, files, scripts, agents, or programs, (f) interfere with or
            disrupt the integrity or performance of the App Services or the data
            contained therein, or (g) attempt to gain unauthorized access to the
            App Services or its related systems or networks.
          </p>
          <p>
            Quickpage reserves the right to remove content or User Submissions
            without prior notice. Quickpage also reserves the right to decide
            whether content or User Submissions are appropriate and in
            compliance with these Terms for violations other than copyright
            infringement and violations of intellectual property law.
          </p>
          <p>
            The App Services serve as venue to allow users to communicate for
            various reasons. One potential use is for the offer for sale of a
            specific product or service to potential buyers. You acknowledge and
            understand that when using the App Services, you will be exposed to
            User Submissions from a variety of sources, including without
            limitation, other users comments and communications in chats, third
            party sources and sites, and pages created by salespeople. Quickpage
            is not involved in any transactions of any kind between users, even
            though Quickpage may, from time to time, provide tools that enable
            users to communicate directly regarding a potential transaction.
            Quickpage is not responsible for the accuracy, usefulness, safety,
            or intellectual property rights of or related to such information,
            nor does Quickpage accept responsibility for such information. You
            further acknowledge and understand that you may be exposed to User
            Submissions that are inaccurate, offensive, indecent, or
            objectionable, and you agree to waive, and hereby do waive, any
            legal or equitable rights or remedies you have or may have against
            Quickpage with respect thereto. Further, the quality, safety, or
            legality of any products and services that are the focus of
            communication between users through the App Services are solely the
            responsibility of the users.
          </p>
          <p>
            <strong>5. Digital Millennium Copyright Act</strong>
          </p>
          <p>
            We comply with the provisions of the Digital Millennium Copyright
            Act applicable to Internet service providers (17 U.S.C. §512, as
            amended). If you have an intellectual property rights-related
            complaint about material posted on the Service, you may contact our
            Designated Agent at the following address:
            <strong>Quickpage Inc.</strong>
            ATTN: Legal Department (Copyright Notification) 2608 2nd Avenue
            #192, Seattle, WA, 98121 Email:{" "}
            <a href="mailto:contact@quickpageapp.com">
              contact@quickpageapp.com
            </a>
          </p>
          <p>
            Any notice alleging that materials hosted by or distributed through
            the App Services infringe intellectual property rights must comply
            with elements of notification as described in 17 U.S.C. §512.
          </p>
          <p>
            Quickpage will promptly terminate the accounts of users that are
            determined by Quickpage to be repeat infringers. A repeat infringer
            is a user who has been notified of infringing activity more than
            once or has had a User Submission removed from the App Services more
            than twice.
          </p>
          <p>
            <strong>6. Intellectual Property Rights</strong>
          </p>
          <p>
            The design of the App Services, along with Quickpage’s created text,
            scripts, graphics, interactive features, and the like (except User
            Submissions, as defined below), and the trademarks, service marks,
            and logos, if any, contained therein (“Marks”) are owned by or
            licensed to Quickpage, subject to copyright and other intellectual
            property rights under United States laws, foreign laws, and
            international conventions. The App Services are provided to you AS
            IS for your information and personal use only. Quickpage reserves
            all rights not expressly granted in and to the App Services.
          </p>
          <p>
            <strong>7. Links</strong>
          </p>
          <p>
            Links provided within the App Services may provide users with the
            ability to connect to other websites and services that are not under
            Quickpage’s control. We do not endorse and are not responsible for
            the content of such websites and/or services. You access such
            websites and use such services at your own risk.
          </p>
          <p>
            <strong>8. Privacy</strong>
          </p>
          <p>
            The privacy and security of your information is important to us. Our
            Privacy Policy is incorporated into these Terms and also governs
            your use of the App Services. To the extent there is a conflict
            between these Terms and the terms of the Privacy Policy, these Terms
            will govern.
          </p>
          <p>
            The Privacy Policy describes potential data that we may gather about
            or from users of the App Services and how we process, use, or share
            such data. By using the App Services, you consent to all actions
            that we take with respect to your data consistent with our Privacy
            Policy. Please review the Privacy Policy for a more complete
            understanding of what information we may collect and how we may use
            such information.
          </p>
          <p>
            <strong>9. Changes to Terms</strong>
          </p>
          <p>
            We reserve the right, at any time and from time to time, for any
            reason, to add to, delete, or modify the Terms provided herein, as
            well as any of our App Services. The effective date of these Terms
            will be displayed at the bottom of the page. If you use the App
            Services after changes become effective, it means you have expressly
            agreed to be bound by the revised terms. We recommend that each user
            review the Terms on a regular and frequent basis in order to be
            fully aware of the current rights and obligations that apply to
            usage of the App Services.
          </p>
          <p>
            <strong>10. Provision of App Services</strong>
          </p>
          <p>
            We are constantly looking for ways to improve the App Services in
            order to provide the best possible experience for you and other
            users. As such, you acknowledge and agree that the App Services, or
            certain features of the App Services, may change from time to time
            without notice. Any new feature that augments, enhances, or modifies
            the current App Services is subject to these Terms.
          </p>
          <p>
            You acknowledge and agree that we may decline to provide access to
            the App Services or permanently or temporarily stop providing the
            App Services, or any feature, program, or content with the App
            Services, to you or other users at our sole discretion, without
            notice or liability to you. You may stop using any part of the App
            Services at any time. You do not need to specifically inform us when
            you stop using the App Services.
          </p>
          <p>
            If you use a mobile device to access the App Services, the following
            additional terms and conditions also apply (“Mobile Terms”):
          </p>
          <ol>
            <li>
              (a) You agree that you are solely responsible for all message and
              date rates and/or charges that apply to use of your mobile device
              to access the App Services. All such rates and charges are billed
              by and payable to your mobile service provider. Please contact
              your participating mobile service provider for pricing plans,
              participation status, and details.
            </li>
            <li>
              (b) You understand that wireless service maybe not be available in
              all areas and at all times and may be affected by product,
              software, coverage, or service changes made by your service
              provider.
            </li>
            <li>
              (c) Additional terms and conditions may apply to your use of the
              App Services based on the type of mobile device you use.
            </li>
          </ol>
          <p>
            YOUR ACCESS TO AND USE OF THE APP SERVICES VIA YOUR MOBILE DEVICE
            CONFIRMS YOUR AGREEMENT TO BE BOUND BY THESE TERMS, AS WELL AS THE
            REMAINING TERMS HEREIN.
          </p>
          <p>
            <strong>11. Indemnity</strong>
          </p>
          <p>
            You are responsible for your use of the App Services, and you will
            defend and indemnify Quickpage and its officers, directors,
            employees, consultants, affiliates, subsidiaries and agents from and
            against every claim brought by a third party, and any related
            liability, damage, loss, and expense, including reasonable
            attorneys' fees and costs, arising out of or connected with: (a)
            your use of, or misuse of, the App Services; (b) your violation of
            any portion of these Terms, any representation, warranty, or
            agreement referenced in these Terms, or any applicable law or
            regulation; (c) your violation of any third party right, including
            any intellectual property right or publicity, confidentiality, other
            property, or privacy right; or (d) any dispute or issue between you
            and any third party. Quickpage reserves the right, at its own
            expense, to assume the exclusive defense and control of any matter
            otherwise subject to indemnification by you (without limiting your
            indemnification obligations with respect to that matter), and in
            that case, you agree to cooperate with our defense of those claims.
          </p>
          <p>
            <strong>12. Disclaimer of Warranty</strong>
          </p>
          <p>
            YOU AGREE THAT YOUR USE OF THE APP SERVICES SHALL BE AT YOUR SOLE
            RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, QUICKPAGE, ITS
            OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES,
            EXPRESS OR IMPLIED, IN CONNECTION WITH THE APP SERVICES AND YOUR USE
            THEREOF. QUICKPAGE MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            ACCURACY OR COMPLETENESS OF THE APP SERVICES, OR ANY CONTENT
            THEREIN, AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR (A) ANY
            ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (B) ANY PERSONAL
            INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM
            YOUR ACCESS TO OR USE OF THE APP SERVICES, (C) ANY UNAUTHORIZED
            ACCESS TO OR USE OF OUR SERVERS AND/OR ANY AND ALL PERSONAL OR
            FINANCIAL INFORMATION STORED THEREIN, (D) ANY INTERRUPTION OR
            CESSATION OF TRANSMISSION TO OR FROM, OR CONNECTION WITH, OUR APP
            SERVICES, ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
            TRANSMITTED TO OR THROUGH OUR SERVICES THROUGH THE ACTIONS OF ANY
            THIRD PARTY, (E) OR ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR
            ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF
            ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE
            AVAILABLE VIA THE APP SERVICES.
          </p>
          <p>
            QUICKPAGE DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSSUME
            RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY
            OR THROUGH A USER SUBMISSION OR OTHER ADVERTISING, BY A THIRD PARTY
            THROUGH THE APP SERVICES, OR BY ANY HYPERLINKED WEBSITE. QUICKPAGE
            WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
            ANY TRANSACTION BETWEEN YOU AND OTHER USERS OR THIRD PARTY PROVIDERS
            OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR
            SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE
            YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
          </p>
          <p>
            <strong>13. Limitation of Liability</strong>
          </p>
          <p>
            IN NO EVENT SHALL QUICKPAGE, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR
            AGENTS BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
            PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM (A) ANY
            ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (B) ANY PERSONAL
            INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM
            YOUR ACCESS TO AND USE OF OUR APP SERVICES, (C) ANY UNAUTHORIZED
            ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
            AND/OR FINANCIAL INFORMATION STORED THEREIN, (D) ANY INTERRUPTION OF
            CESSASION OF TRANSMISSION TO OR FROM, OR CONNECTION WITH, OUR APP
            SERVICES, ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
            TRANSMITTED TO OR THROUGH OUR SERVICES THROUGH THE ACTIONS OF ANY
            THIRD PARTY, (E) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY
            LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
            CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE
            VIA THE APP SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR
            ANY OTHER LEGAL THEORY, AND WHETHER OR NOT QUICKPAGE IS ADVISED OF
            THE POSSIBLITY OF SUCH DAMAGES, OR (F) ANY DISCLOSURE OF INFORMATION
            PURSUANT TO THESE TERMS OR THE PRIVACY POLICY. THE AGGREGATE
            LIABILITY OF QUICKPAGE TO YOU FOR ALL CLAIMS ARISING OUT OF OR
            RELATING TO THE USE OF OR ANY INABILITY TO USE ANY PORTION OF THE
            APP SERVICES OR OTHERWISE UNDER THESE TERMS, WHETHER IN CONTRACT,
            TORT, OR OTHERWISE, IS LIMITED TO THE GREATER OF: (A) THE AMOUNT YOU
            HAVE PAID TO QUICKPAGE FOR ACCESS TO AND USE OF THE APP SERVICES IN
            THE 12 MONTHS PRIOR TO THE EVENT OR CIRCUMSTANCE GIVING RISE TO
            CLAIM; OR (B) $100.
          </p>
          <p>
            THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST
            EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
          </p>
          <p>
            The App Services are controlled and offered by Quickpage Inc. from
            its location in the United States of America. Quickpage makes no
            representations that the App Services are appropriate or available
            for use in other locations. Those who wish to access or use the App
            Services from other jurisdictions do so on their own volition and
            are responsible for compliance with local law.
          </p>
          <p>
            <strong>14. Indemnity</strong>
          </p>
          <p>
            You agree to defend, indemnify, and hold harmless Quickpage, its
            officers, directors, employees, and agents, from and against any and
            all claims, damages, obligations, losses, costs or debt, and
            expenses (including, but not limited to, attorney’s fees) arising
            from (a) your use of and access to the App Services, (b) your
            violation of any term of these Terms, (c) your violation of any
            third party right, including without limitation any copyright,
            property, or privacy right, or (d) any claim that one of your User
            Submissions caused damage to a third party. These defense and
            indemnification obligations will survive these Terms and your use of
            the App Services.
          </p>
          <p>
            <strong>15. General Provisions</strong>
          </p>
          <p>
            You further agree to be subject to the jurisdiction of the state of
            Washington in the event of any legal dispute. These Terms of Service
            shall be governed by the laws of the state of Washington, without
            respect to its conflict of laws principles. Any claim or dispute
            between you and Quickpage that arises in whole or in part from the
            App Services shall be decided exclusively by a court of competent
            jurisdiction in King County, Washington.
          </p>
          <p>
            These Terms, together with the Privacy Policy and any other legal
            notices published by Quickpage, shall constitute the entire
            agreement between you and Quickpage concerning the App Services. If
            any provision of these Terms is deemed invalid by a court of
            competent jurisdiction, the invalidity of such provision shall not
            affect the validity of the remaining provisions of these Terms,
            which shall remain in full force and effect.
          </p>
          <p>
            No waiver of any term in these Terms shall be deemed a further or
            continuing waiver of such term or any other term, and Quickpage’s
            failure to assert any right or provision under these Terms shall not
            constitute a waiver of such right or provision.
          </p>
        </Content>
      </Contain>
      <Cta location={location} />
    </Layout>
  );
};
